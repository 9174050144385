<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('common.edit')"
    :confirmLoading="loading"
    :mask-closable="false"
    :ok-text="$t('account.modify')"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="{ style: { width: '100px' } }"
    >
      <a-form-item
        :label="$t('warehouse.delivery_way')"
        name="deliveryType"
      >
        <a-select
          v-model:value="formState.deliveryType"
          :placeholder="$t('common.please_select')"
        >
          <a-select-option
            v-for="(item, index) in deliveryWayEnumList"
            :key="index"
            :value="item"
            :title="$t($enumLangkey('deliveryWay', item))"
            >{{ $t($enumLangkey("deliveryWay", item)) }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item
        :label="$t('warehouse.return_the_parcel')"
        name="packageNo"
      >
        <a-input
          v-model:value="formState.packageNo"
          :placeholder="
            $t('warehouse.parcel_tracking_number') +
            $t('warehouse.scannable_number')
          "
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from 'vue';
import { Modal, Form, Input, Select } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { isShipmentNoValid } from "@/utils/regexpValidator";
import { deliveryWay as deliveryWayEnum } from "@/enum/enum.json";
import { emit, nextTick } from 'process';

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      deliveryWayEnumList: [],
      formState: {
        deliveryType: null,
        packageNo: null,
      }
    })
    state.deliveryWayEnumList = Object.values(deliveryWayEnum);

    const validatePackageNo = async (rule, value) => {
      if (!value) {
        return Promise.reject(vueI18n.t("common.p0_is_required", [
          vueI18n.t("warehouse.return_the_parcel"),
        ]))
      } else {
        let sourceType = state.formState.deliveryType
        if (!isShipmentNoValid(sourceType, value)) {
          return Promise.reject(vueI18n.t("warehouse.please_enter_the_correct_p0", [
            vueI18n.t("warehouse.return_the_parcel"),
          ]))
        } else {
          return Promise.resolve();
        }
      }
    }

    const rules = {
      deliveryType: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.delivery_way"),
          ]),
      },
      packageNo: {
        validator: validatePackageNo,
        trigger: ['blur', 'change']
      },
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate()
        state.loading = true
        emit('confirm', JSON.parse(JSON.stringify(state.formState)))
      } catch (error) {
        
      }
    }

    const close = () => {
      state.visible = false
    }

    const hideLoading = () => {
      state.loading = false
    }

    const show = (deliveryType, packageNo) => {
      state.visible = true
      nextTick(() => {
        formRef.value.resetFields()
        state.formState.deliveryType = deliveryType
        state.formState.packageNo = packageNo
      })
    }

    return {
      ...toRefs(state),
      formRef,
      rules,
      close,
      hideLoading,
      show,
      handleConfirm,
    }
  },
})
</script>
