<template>
  <Content>
    <template #contentTitle>{{
      $t("menu.consignment_return_channel_detail")
    }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row :gutter="[0, 6]">
          <a-col :xl="16" :lg="18" :md="19" :sm="19">
            <a-row :gutter="[0, 6]">
              <a-col :span="24">
                <a-row :gutter="[0, 6]">
                  <a-col :xxl="7" :xl="10" :md="10" :sm="24" :xs="24">
                    <label>{{ $t("warehouse.return_the_parcel") }}: </label>
                    {{ returnInfo.packageNo }}
                    <template
                      v-if="
                        !returnInfo.status ||
                        returnInfo.status === returnPlanStatusEnum.planned
                      "
                    >
                      <small
                        style="color: #aaaaaa"
                        v-if="returnInfo.updateNum > 0"
                        >({{ $t("warehouse.modify_the_number_of_times") }}:
                        {{ returnInfo.updateNum }})</small
                      >
                    </template>
                    <template
                      v-if="
                        !returnInfo.status ||
                        returnInfo.status === returnPlanStatusEnum.planned
                      "
                    >
                      <a-col v-if="returnInfo.updateNum > 0">
                        <a-button size="small" @click="handleShowEditModal">{{
                          $t("account.modify")
                        }}</a-button>
                      </a-col>
                    </template>
                  </a-col>
                  <a-col :xxl="7" :xl="10" :md="10" :sm="24" :xs="24">
                    <label>{{ $t("warehouse.return_plan_no") }}: </label>
                    {{ returnInfo.channelReturnPlanNo }}
                  </a-col>

                  <a-col :xxl="5" :xl="10" :md="10" :sm="24" :xs="24">
                    {{ $t("warehouse.creation_time") }}:
                    {{ $filters.utcToCurrentTime(returnInfo.creationTime) }}
                  </a-col>
                  <a-col :xxl="5" :xl="10" :md="10" :sm="24" :xs="24">
                    {{ $t("warehouse.update_time") }}:
                    {{
                      $filters.utcToCurrentTime(returnInfo.lastModificationTime)
                    }}
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="24">
                <a-row :gutter="[0, 6]">
                  <a-col :xxl="7" :xl="10" :md="10" :sm="24" :xs="24">
                    {{ $t("warehouse.standby_identification1") }}:
                    {{ returnInfo?.packageNo1??"-" }}
                  </a-col>
                  <a-col
                    >{{ $t("warehouse.standby_identification2") }}:
                    {{ returnInfo?.packageNo2??"-" }}
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="24">
                <a-row :gutter="[0, 6]">
                  <a-col :xxl="7" :xl="10" :md="10" :sm="24" :xs="24">
                    {{ $t("warehouse.the_goods_warehouse") }}:
                    {{ returnInfo.warehouseNo }}
                  </a-col>
                  <a-col
                    >{{ $t("warehouse.warehouse_address") }}:
                    <span v-if="returnInfo.receiveWarehouseAddress">
                      <span>{{ getAddressInfo() }}</span>
                      <span class="ml-1"
                        >({{ $t("logistics.contacts") }}:
                        {{ getLinkman(returnInfo.receiveWarehouseAddress) }}
                        )</span
                      >
                    </span>
                  </a-col>
                </a-row>
              </a-col>

              <a-col :span="24">
                <a-row :gutter="[0, 6]">
                  <a-col :xxl="7" :xl="10" :md="10" :sm="24" :xs="24"
                    >{{ $t("warehouse.delivery_way") }}:
                    {{
                      $t($enumLangkey("deliveryWay", returnInfo.deliveryType))
                    }}
                  </a-col>
                  <a-col :xxl="7" :xl="10" :md="10" :sm="24" :xs="24"
                    >{{ $t("warehouse.return_of_the_source") }}:
                    {{
                      $t(
                        $enumLangkey(
                          "returnPlanSourceType",
                          returnInfo.sourceType
                        )
                      )
                    }}
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="24">
                <a-row :gutter="[0, 6]">
                  <a-col :xxl="7" :xl="10" :md="10" :sm="24" :xs="24">
                    {{ $t("warehouse.trajectory_status") }}:
                    <span v-if="returnInfo.waybillOperationStatus">{{
                      $t(
                        $enumLangkey(
                          "waybillOperationStatus",
                          returnInfo.waybillOperationStatus
                        )
                      )
                    }}</span>
                    <span v-else>-</span>
                  </a-col>
                  <a-col :xxl="17" :xl="14" :md="24" :sm="24" :xs="24">
                    {{$t("warehouse.trajectory")}}:
                    <span v-if="returnInfo.newestTrack">
                        <small>{{ returnInfo.newestTrack?.trackRemark }}</small>
                        <small class="ml-2">{{
                          $filters.utcToCurrentTime(
                            returnInfo.newestTrack?.trackTime
                          )
                        }}</small>
                    </span>
                    <span v-else>-</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col :xl="8" :lg="6" :md="5" :sm="5">
            <a-row>
              <a-col :span="24">
                <div style="font-weight: bold; text-align: center">
                  {{ $t("warehouse.the_parcel_status") }}
                </div>
                <div
                  class="mt-2"
                  style="font-weight: bold; text-align: center; color: #aaaaaa"
                  v-if="returnInfo.status === returnPlanStatusEnum.canceled"
                >
                  {{ $t($enumLangkey("returnPlanStatus", returnInfo.status)) }}
                </div>
                <div
                  class="text-success mt-2"
                  style="font-weight: bold; text-align: center"
                  v-else
                >
                  {{ $t($enumLangkey("returnPlanStatus", returnInfo.status)) }}
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-table
          class="mt-3"
          :columns="columns"
          size="small"
          :scroll="{ x: true }"
          :data-source="returnInfo.planDetail.details"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="{
            defaultPageSize: defaultPageSize,
            hideOnSinglePage: true,
            current: currentPageIndex,
            position: 'both',
          }"
          @change="
            (page) => {
              currentPageIndex = page.current;
            }
          "
        >
          <template #serialNumber="{ index }">{{
            defaultPageSize * (currentPageIndex - 1) + (index + 1)
          }}</template>
          <template #productInfo="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.productImg" />
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>
          <template #packingImgUrl="{ record }">
            <div class="table-list-img-common" v-if="record.productPackingImg">
              <c-image :src="record.productPackingImg" />
            </div>
            <span v-else>-</span>
          </template>
          <template #packagingSize="{ record }">
            {{ record.packSize.length }}x{{ record.packSize.width }}x{{
              record.packSize.height
            }}cm
          </template>
          <template #productWeight="{ record }">
            {{ handleGToKg(record.packSize.weight) }}kg
          </template>
          <template #packingVerifyInfo="{ record }">
            <span
              v-if="
                record.checkPackSize.length &&
                record.checkPackSize.width &&
                record.checkPackSize.height
              "
              >{{ record.checkPackSize.length }}x{{
                record.checkPackSize.width
              }}x{{ record.checkPackSize.height }}cm/{{
                handleGToKg(record.checkPackSize.weight)
              }}kg</span
            >
            <span v-else>{{ $t("warehouse.no_approved_record") }}</span>
          </template>
          <template #isKeepProductPackage="{ record }">
            <span v-if="record.isKeepProductPackage === true">{{
              $t("warehouse.need_to_keep_the_outer_packaging")
            }}</span>
            <span v-else-if="record.isKeepProductPackage === false">{{
              $t("warehouse.do_not_keep_the_outer_packaging")
            }}</span>
            <span v-else>-</span>
          </template>
          <template #optionalServices="{ record }">
            <template
              v-if="
                record.optionService?.isFacadeQC ||
                record.optionService?.isFunctionQC
              "
            >
              <div v-if="record.optionService?.isFacadeQC">
                {{ $t("enum.order_logistics_fee_130") }}
              </div>
              <div v-if="record.optionService?.isFunctionQC">
                {{ $t("enum.order_logistics_fee_150") }}
              </div>
            </template>
            <span v-else>-</span>
          </template>
          <template #scannableInformation>
            {{ $t($enumLangkey("returnPlanFnskuType", returnInfo.fnSkuType)) }}
          </template>
          <template #footer>
            <a-row :gutter="32" type="flex" justify="end">
              <a-col
                >{{ $t("warehouse.product_SKU") }}:
                {{ returnInfo.planDetail.skuCount }}</a-col
              >
              <a-col
                >{{ $t("warehouse.returns_the_total") }}:
                {{ returnInfo.planDetail.returnCount }}</a-col
              >
              <a-col
                >{{ $t("warehouse.received") }}:
                {{ returnInfo.planDetail.receiveNum }}</a-col
              >
              <a-col
                >{{ $t("warehouse.destroyed") }}:
                {{ returnInfo.planDetail.destroyNum }}</a-col
              >
            </a-row>
          </template>
        </a-table>
        <CFee v-if="returnInfo.fee" class="mt-4" :showTotal="false" :fees="formatFees(returnInfo.fee.fees)" />
        <!-- 处理记录 -->
        <a-table
          class="mt-3"
          :columns="recordColumns"
          size="small"
          :scroll="{ x: true }"
          :data-source="tableData.tableList"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="false"
        >
          <template #serialNumber="{ index }">{{
            tableData.pageData.maxResultCount *
              (tableData.pageData.currentIndex - 1) +
            (index + 1)
          }}</template>
          <template #productInfo="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.productImg" />
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>
          <template #packagingSize="{ record }">
            {{ record.packSize.length }}x{{ record.packSize.width }}x{{
              record.packSize.height
            }}cm
          </template>
          <template #productWeight="{ record }">
            {{ handleGToKg(record.packSize.weight) }}kg
          </template>
          <template #checkPackSize="{ record }">
            <span v-if="record.checkPackSize.length">
              {{ record.checkPackSize.length }}x{{
                record.checkPackSize.width
              }}x{{ record.checkPackSize.height }}cm
            </span>
            <span v-else>-cm</span>
            <span v-if="record.checkPackSize.weight">
              / {{ handleGToKg(record.checkPackSize.weight) }}kg
            </span>
            <span v-else>/-kg</span>
          </template>
          <template #isQualified="{ record }">
            <span v-if="record.isQualified === true" class="text-success">{{
              $t("warehouse.is_qualified_1")
            }}</span>
            <span v-else-if="record.isQualified === false" class="text-error">{{
              $t("warehouse.is_qualified_2")
            }}</span>
            <span v-else>-</span>
          </template>
          <template #qualityImgUrls="{ record }">
            <a-popover v-if="record.qualityImgUrls.length">
              <template #content>
                <a-space>
                  <div
                    v-for="(imgItem, index) in record.qualityImgUrls"
                    class="table-list-img-common"
                    :key="index"
                  >
                    <c-image :src="imgItem" :ossHeight="800" :ossWidth="800" />
                  </div>
                </a-space>
              </template>
              <span class="text-primary" style="font-size: 20px">
                <FileImageOutlined />
              </span>
            </a-popover>
            <span v-else>-</span>
          </template>
          <template #status="{ record }">
            <span
              v-if="record.status === returnPlanRecordStatusEnum.inStorage"
              class="text-success"
              >{{
                $t($enumLangkey("returnPlanRecordStatus", record.status))
              }}</span
            >
            <span
              v-if="record.status === returnPlanRecordStatusEnum.destruction"
              class="text-error"
              >{{
                $t($enumLangkey("returnPlanRecordStatus", record.status))
              }}</span
            >
          </template>
          <template #title>
            {{ $t("warehouse.processing_records") }}
          </template>
          <template #footer>
            <a-row :gutter="32" type="flex" justify="end">
              <a-col v-if="returnReceiveFee"
                >{{ $t("warehouse.destruction_handling_charge") }}:
                {{ returnReceiveFee.currencySymbol
                }}{{
                  $filters.amountToFixed2(returnReceiveFee.totalAmount)
                }}</a-col
              >
            </a-row>
          </template>
        </a-table>
        <CPager
          class="text-center mt-2"
          @handlePage="handlePage"
          :page-data="tableData.pageData"
        ></CPager>
        <a-row class="mt-3 mr-3" type="flex" justify="end">
          <a-col>
            <a-form
              v-if="returnInfo.fee"
              :label-col="{ style: { width: '200px' } }"
              :wrapper-col="{ style: { width: '150px' } }"
            >
              <a-divider style="margin-bottom: 0" />
              <a-form-item
                style="margin-bottom: 0"
                :label="$t('warehouse.total_prices')"
              >
                <template
                  v-if="
                    returnInfo.fee.actualTotalAmount &&
                    returnInfo.fee.totalAmount !==
                      returnInfo.fee.actualTotalAmount
                  "
                >
                  <del style="color: #aaaaaa"
                    >{{ returnInfo.fee.currencySymbol
                    }}{{
                      $filters.amountToFixed2(returnInfo.fee.totalAmount)
                    }}</del
                  >
                  <span class="ml-2"
                    >{{ returnInfo.fee.currencySymbol
                    }}{{
                      $filters.amountToFixed2(returnInfo.fee.actualTotalAmount)
                    }}</span
                  >
                </template>
                <span v-else
                  >{{ returnInfo.fee.currencySymbol
                  }}{{
                    $filters.amountToFixed2(returnInfo.fee.totalAmount)
                  }}</span
                >
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
      </a-spin>
      <a-row class="mt-4 mr-5 mb-3" type="flex" justify="end">
        <a-col>
          <a-space>
            <a-button v-if="
                !returnInfo.status ||
                returnInfo.status === returnPlanStatusEnum.planned
              "
              @click="handleCancelPlan"
            >{{ $t("warehouse.cancel_plan") }}</a-button>
            <a-button type="primary" @click="handleBack">{{
              $t("common.backtrack")
            }}</a-button>
          </a-space>
        </a-col>
      </a-row>
      <EditPackageNoModal
        ref="editPackageNoModal"
        @confirm="handleEditPackageNo"
      />
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage";
import CPager from "@/views/components/CPager.vue";
import CFee from "@/views/components/CFee.vue";
import EditPackageNoModal from "./components/EditPackageNoModal.vue";
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Table,
  Checkbox,
  Space,
  Form,
  Spin,
  Popover,
  Divider,
  Modal,
  message,
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import {
  getDetailById,
  getOperateReceive,
  editPackageNo,
  refundChannelReturnPlan,
} from "@/api/modules/consignment/returnChannel";
import { useRoute, useRouter } from "vue-router";
import { useTab } from "@/hooks/tabs/index";
import { useStore } from "vuex";
import { getAddressByLanguageV2, gToKg, getLinkman } from "@/utils/general";
import {
  returnPlanStatus as returnPlanStatusEnum,
  returnPlanRecordStatus as returnPlanRecordStatusEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "consignment_return_channel_detail",
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ACheckbox: Checkbox,
    ASpace: Space,
    AForm: Form,
    AFormItem: Form.Item,
    APopover: Popover,
    ADivider: Divider,
    Content,
    CImage,
    CPager,
    CFee,
    EditPackageNoModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();

    const defaultPageSize = ref(50);
    const currentPageIndex = ref(1);
    const editPackageNoModal = ref(null);

    const state = reactive({
      channelReturnPlanId: null,
      loading: false,
      packageNo: null,
      returnInfo: {
        packageNo: null,
        packageNo1: null,
        packageNo2: null,
        warehouseNo: null,
        channelReturnPlanNo: null,
        creationTime: null,
        lastModificationTime: null,
        status: null,
        deliveryType: null,
        sourceType: null,
        receiveWarehouseAddress: null,
        waybillOperationStatus: null,
        optionService: null,
        fnSkuType: null,
        newestTrack:null,
        planDetail: {
          skuCount: 0,
          returnCount: 0,
          receiveNum: 0,
          destroyNum: 0,
          details: [],
        },
        fee: null,
        updateNum: 0,
      },
      returnReceiveFee: null,
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        width: 50,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        width: 150,
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
      },
      {
        width: 80,
        title: () => vueI18n.t("logistics.packaging_figure"),
        slots: {
          customRender: "packingImgUrl",
        },
      },
      {
        width: 100,
        title: "SESKU",
        dataIndex: "seSku",
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.business_packaging_size"),
        slots: {
          customRender: "packagingSize",
        },
      },
      {
        width: 80,
        title: () => vueI18n.t("warehouse.product_weight"),
        slots: {
          customRender: "productWeight",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.approved_packaging_info"),
        slots: {
          customRender: "packingVerifyInfo",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.whether_to_keep_the_outer_packaging"),
        slots: {
          customRender: "isKeepProductPackage",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.optional_services"),
        slots: {
          customRender: "optionalServices",
        },
      },
      {
        dataIndex: "fnSku",
        width: 120,
        slots: {
          title: "scannableInformation",
        },
      },
      {
        dataIndex: "num",
        width: 80,
        title: () => vueI18n.t("logistics.count"),
      },
      {
        dataIndex: "receiveNum",
        width: 80,
        title: () => vueI18n.t("warehouse.received_quantity"),
      },
      {
        dataIndex: "destroyNum",
        width: 80,
        title: () => vueI18n.t("warehouse.destroyed"),
      },
    ];

    const recordColumns = [
      {
        width: 60,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        width: 200,
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
      },
      {
        width: 100,
        title: "SESKU",
        dataIndex: "seSku",
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.business_packaging_size"),
        slots: {
          customRender: "packagingSize",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.product_weight"),
        slots: {
          customRender: "productWeight",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.approved_packaging_info"),
        slots: {
          customRender: "checkPackSize",
        },
      },
      {
        dataIndex: "fnSku",
        width: 150,
        title: () => vueI18n.t("warehouse.identifiable_information"),
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.quality_inspection_results"),
        slots: {
          customRender: "isQualified",
        },
      },
      {
        width: 100,
        slots: {
          customRender: "qualityImgUrls",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.the_processing_results"),
        slots: {
          customRender: "status",
        },
      },
    ];

    const formatFees = (list) => {
      if (Array.isArray(list)) {
        return list.map(item => ({
          feeType: item.feeType,
          symbol: state.returnInfo.fee?.currencySymbol ?? '',
          value: item.amount,
          checkValue: item.actualAmount,
        }))
      }
      return [];
    }

    const getAddressInfo = () => {
      if (!state.returnInfo.receiveWarehouseAddress) {
        return "";
      }
      return getAddressByLanguageV2(
        state.returnInfo.receiveWarehouseAddress,
        getters.language
      );
    };

    const handleEditPackageNo = (editData) => {
      const data = Object.assign({}, editData, {
        channelReturnPlanId: state.channelReturnPlanId,
      });
      editPackageNo(data)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          state.returnInfo.updateNum--;
          state.returnInfo.packageNo = data.packageNo;
          editPackageNoModal.value.close();
        })
        .finally(() => {
          editPackageNoModal.value.hideLoading();
        });
    };

    const handleGToKg = (val) => {
      if (!val) {
        return 0;
      }
      return gToKg(val);
    };

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "consignment_return_channel_list" });
    };

    const handleCancelPlan = () => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return refundChannelReturnPlan({ id: state.channelReturnPlanId })
                  .then(() => {
                    message.success(vueI18n.t("common.successfully_canceled"));
                    handleBack();
                  });
        },
      });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getReceiveData();
    };

    const getReceiveData = () => {
      state.tableData.loading = true;
      const data = Object.assign({}, state.tableData.pageData, {
        channelReturnPlanId: state.channelReturnPlanId,
      });
      getOperateReceive(data)
        .then(({ result }) => {
          let { totalCount = 0, items = [], fee = null } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
          state.returnReceiveFee = fee;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleShowEditModal = () => {
      editPackageNoModal.value.show(
        state.returnInfo.deliveryType,
        state.returnInfo.packageNo
      );
    };

    onMounted(async () => {
      if ("id" in route.params) {
        try {
          state.loading = true;
          state.channelReturnPlanId = route.params.id;
          let { result: returnInfo } = await getDetailById({
            channelReturnPlanId: state.channelReturnPlanId,
          });
          for (const key in state.returnInfo) {
            if (Object.hasOwnProperty.call(returnInfo, key)) {
              state.returnInfo[key] = returnInfo[key];
            }
          }
          getReceiveData();
        } catch (error) {
        } finally {
          state.loading = false;
        }
      } else {
        message.error(vueI18n.t("common.exception"));
      }
    });

    return {
      ...toRefs(state),
      editPackageNoModal,
      columns,
      recordColumns,
      defaultPageSize,
      currentPageIndex,
      returnPlanStatusEnum,
      returnPlanRecordStatusEnum,
      handleEditPackageNo,
      handleBack,
      formatFees,
      getAddressInfo,
      handleGToKg,
      handleCancelPlan,
      handlePage,
      handleShowEditModal,
      getLinkman,
    };
  },
});
</script>
